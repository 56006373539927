import SAMSUNGF14 from "assets/samsung/SAMSUNGF14.jpg";
import REALMEC63 from "assets/realme/REALMEC63.jpg";
import REALME11 from "assets/realme/REALME11.jpg";
import REALME12 from "assets/realme/REALME12.jpg";
//mi
import REDMI13CBLACK1 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack1.jpg";

import REDMI13CGREEN1 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen1.jpg";

import REDMI13CSILVER1 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver1.jpg";

import REDMINOTE13WHITE1 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE1.jpg";

import REDMINOTE13PURPLE1 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE1.jpg";

import REDMINOTE13GOLD1 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD1.jpg";

import REDMI135GBLACKDIAMOND1 from "assets/mi/REDMI135G/REDMI135GBLACKDIAMOND/REDMI135GBLACKDIAMOND1.jpg";

import REDMINOTE13PROPLUS5GFUSIONBLACK1 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONBLACK/REDMINOTE13PROPLUS5GFUSIONBLACK1.jpg";

//oppo import

import OPPOFINDX8PROSPACEBLACK1 from "assets/oppo/OPPOFINDX8PRO/OPPOFINDX8PROSPACEBLACK/OPPOFINDX8PROSPACEBLACK1.jpeg";

import OPPOFINDX8SPACEBLACK1 from "assets/oppo/OPPOFINDX8/OPPOFINDX8SPACEBLACK/OPPOFINDX8SPACEBLACK1.jpeg";

import OPPOFINDX8STARGREY1 from "assets/oppo/OPPOFINDX8/OPPOFINDX8STARGREY/OPPOFINDX8STARGREY1.jpeg";

import OPPOA17kGOLD1 from "assets/oppo/OPPOA17K/OPPOA17KGOLD/OPPOA17KGOLD1.jpg";

import OPPOA77ORANGE1 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE1.jpg";

import OPPOK12XBLUE1 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE1.jpg";

import OPPOK12XVOILET1 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET1.jpg";

import OPPORENO12BROWN1 from "assets/oppo/OPPORENO12/OPPORENO12MATTEBROWN/OPPORENO12MATTEBROWN1.jpg";

import OPPORENO12PROBROWN1 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSPACEBROWN/OPPORENO12PROSPACEBROWN1.jpg";

import OPPORENO12PROGOLD1 from "assets/oppo/OPPORENO12PRO/OPPORENO12PROSUNSETGOLD/OPPORENO12PROSUNSETGOLD1.jpg";

import OPPOF27PROPLUSDUSKPINK1 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSDUSKPINK/OPPOF27PROPLUSDUSKPINK1.jpg";

import OPPOF27PROPLUSMIDNIGHTNAVY1 from "assets/oppo/OPPOF27PROPLUS/OPPOF27PROPLUSMIDNIGHTNAVY/OPPOF27PROPLUSMIDNIGHTNAVY1.jpg";

import OPPOA3XSPARKLEBLACK1 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK1.jpg";

import OPPOF27AMBERORANGE1 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE1.jpg";

import OPPOF27EMERALDGREEN1 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN1.jpg";

import OPPOA3PROMOONLIGHTPURPLE1 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE1.jpg";

import OPPOA3PROSTARRYBLACK1 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK1.jpg";

//vivo

import VIVOY3005GPHANTOMPURPLE1 from "assets/vivo/VIVOY3005G/VIVOY3005GPHANTOMPURPLE/VIVOY3005GPHANTOMPURPLE1.jpg";

import VIVOV40GANGESBLUE1 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE1.jpg";

import VIVOV40LOTUSPURPLE1 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE1.jpg";

import VIVOY58HIMALAYANBLUE1 from "assets/vivo/VIVOY58/VIVOY58HIMALAYANBLUE/VIVOY58HIMALAYANBLUE1.jpg";

import VIVOV40EMINTGREEN1 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN1.jpg";

import VIVOV40EROYALBRONZE1 from "assets/vivo/VIVOV40E/VIVOV40EROYALBRONZE/VIVOV40EROYALBRONZE1.jpg";

import VIVOY18IPACEBLACK1 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK1.jpg";

import VIVOY28EBREEZEGREEN1 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN1.jpg";

import VIVOY28EVINTAGERED1 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED1.jpg";

import VIVOY28STWINKLINGPURPLE1 from "assets/vivo/VIVOY28S/VIVOY28STWINKLINGPURPLE/VIVOY28STWINKLINGPURPLE1.jpg";

import VIVOY28SVINTAGERED1 from "assets/vivo/VIVOY28S/VIVOY28SVINTAGERED/VIVOY28SVINTAGERED1.jpg";

import VIVOY300PLUSSILKGREEN1 from "assets/vivo/VIVOY300PLUS/VIVOY300PLUSSILKGREEN/VIVOY300PLUSSILKGREEN1.jpg";

import Y28CRYSTALPURPLE1 from "assets/vivo/VIVOY28/Y28CRYSTALPURPLE/Y28CRYSTALPURPLE1.jpeg";
import VIVOY200EBLACKDIAMOND1 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND1.jpg";

import VIVOX200NATURALGREEN1 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN1.jpeg";

import VIVOX200PROTITANIUMGREY1 from "assets/vivo/VIVOX200PRO/VIVOX200TITANIUMGREY/VIVOX200PROTITANIUMGREY1.jpg";

//samsung
import SAMSUNGA165GGOLD1 from "assets/samsung/SASMSUNGA165G/SAMSUNGA165GGOLD/SAMSUNGA165GGOLD1.jpeg";

import SASMSUNGA165GBLUEBLACK1 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GBLUEBLACK/SASMSUNGA165GBLUEBLACK1.jpeg";

import A05SBLACK1 from "assets/samsung/A05S/A05SBLACK/A05SBLACK1.jpeg";

import SAMSUNGA06BLACK1 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK1.jpg";

import SAMSUNGA15BLUE1 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUE/SAMSUNGA15BLUE1.jpg";

import SAMSUNGA15BLUEBLACK1 from "assets/samsung/SAMSUNGA15/SAMSUNGA15BLUEBLACK/SAMSUNGA15BLUEBLACK1.jpg";

import SAMSUNGA15LIGHTBLUE1 from "assets/samsung/SAMSUNGA15/SAMSUNGA15LIGHTBLUE/SAMSUNGA15LIGHTBLUE1.jpg";

import SAMSUNGA35AWESOMEICEBLUE1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE1.jpg";

import SAMSUNGA35AWESOMELILAC1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC1.jpg";

import SAMSUNGA55AWESOMENAVY1 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY1.jpg";

import SAMSUNGA55BLUE1 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE1.jpg";

import SAMSUNGA145GBLACK1 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK1.jpg";

import SAMSUNGF15ASHBLACK1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK1.jpg";

import SAMSUNGF15GROOVYVIOLET1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET1.jpg";

import SAMSUNGF15JAZZYGREEN1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15JAZZYGREEN/SAMSUNGF15JAZZYGREEN1.jpg";

import SAMSUNGM14SPPHIREBLUE1 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE1.jpg";

import SAMSUNGM15BLUETOPAZ1 from "assets/samsung/SAMSUNGM15/SAMSUNGM15BLUETOPAZ/SAMSUNGM15BLUETOPAZ1.jpg";

import SAMSUNGS23CREAM1 from "assets/samsung/SAMSUNGS23/SAMSUNGS23CREAM/SAMSUNGS23CREAM1.jpg";

import SAMSUNGS23LAVENDER1 from "assets/samsung/SAMSUNGS23/SAMSUNGS23LAVENDER/SAMSUNGS23LAVENDER1.jpg";

import SAMSUNGS245GMARBLEGRAY1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY1.jpg";

import SAMSUNGS24PLUS5GCOBALTVIOLET1 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET1.jpeg";

import SAMSUNGS24ULTRA5GTITANIUMGRAY1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY1.jpg";

import SAMSUNGS24FE5GBLUE1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE1.jpg";

import SAMSUNGS24FE5GGRAPHITE1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE1.jpg";

import SAMSUNGA144GLIGHTGREEN1 from "assets/samsung/SAMSUNGA144G/SAMSUNGA144GLIGHTGREEN/SAMSUNGA144GLIGHTGREEN1.jpg";

//tecno

import TECNOCAMON20ARTEDITION1 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION1.jpg";

import TECNOCAMON20PROSERENITYBLUE1 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE1.jpg";

import TECNOCAMON30BASALTICDARK1 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK1.jpg";

import TECNOCAMON30PREMIERLAVABLACK1 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK1.jpg";

import TECNOPHANTOMX2MOONLIGHTSILVER1 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER1.jpg";

import TECNOPHANTOMX2PROMARSORANGE1 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE1.jpg";

import TECNOPOVA6NEOAURORACLOUD1 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD1.jpeg";

import TECNOPOVA6PROCOMETGREEN1 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN1.jpeg";

import TECNOSPARK30CAURORCLOUD1 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD1.jpeg";

//IQOO

import IQOONEO9PROFIERYRED1 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROFIERYRED/IQOONEO9PROFIERYRED1.jpeg";

import IQOOZ9GRAPHENEBLUE1 from "assets/iqoo/IQOOZ9/IQOOZ9GRAPHENEBLUE/IQOOZ9GRAPHENEBLUE1.jpeg";

import IQOOZ9LITEMOCHABROWN1 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEMOCHABROWN/IQOOZ9LITEMOCHABROWN1.jpg";

import IQOOZ9SONYXGREEN1 from "assets/iqoo/IQOOZ9S/IQOOZ9SONYXGREEN/IQOOZ9SONYXGREEN1.jpeg";

import IQOOZ9XSTORMGREY1 from "assets/iqoo/IQOOZ9X/IQOOZ9XSTORMGREY/IQOOZ9XSTORMGREY1.jpeg";

import IQOOZ9SPROLUXEMARBLE3 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROLUXEMARBLE/IQOOZ9SPROLUXEMARBLE3.jpeg";

export const ALLPHONES = [
  //VIVO PHONES
  {
    id: "VIVO1",
    name: "VIVO v40 5G 8/256 ",
    brand: "VIVO",
    series: "V40",
    image: VIVOV40LOTUSPURPLE1,
    launchDate: "03-07-2024",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 42999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 7 Gen 3 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO2",
    name: "VIVO v40 5G 8/128 ",
    brand: "VIVO",
    series: "V40",
    image: VIVOV40GANGESBLUE1,
    launchDate: "03-07-2024",
    networkType: "5G",
    salesPrice: 34999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 39999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 7 Gen 3 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO4",
    name: "VIVO v40e 5G 8/256 ",
    brand: "VIVO",
    series: "V40e",
    image: VIVOV40EMINTGREEN1,
    launchDate: "02-10-2024",
    networkType: "5G",
    salesPrice: 30999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 35999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO5",
    name: "VIVO Y200e 5G 8/128 ",
    brand: "VIVO",
    image: VIVOY200EBLACKDIAMOND1,
    launchDate: "22-02-2024",
    networkType: "5G",
    salesPrice: 20999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: false,
    mrp: 25999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO6",
    name: "VIVO Y58 5G 8/128 ",
    brand: "VIVO",
    series: "Y58",
    image: VIVOY58HIMALAYANBLUE1,
    launchDate: "20-06-2024",
    salesPrice: 17499,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 23999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO7",
    name: "VIVO Y300 PLUS 5G 8/128 ",
    brand: "VIVO",
    image: VIVOY300PLUSSILKGREEN1,
    launchDate: "16-10-2024",
    networkType: "5G",
    salesPrice: 23999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.7,
    newArrival: true,
    mrp: 29999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 695 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO8",
    name: "VIVO Y28S 5G 8/128 ",
    brand: "VIVO",
    series: "Y28S",
    image: VIVOY28STWINKLINGPURPLE1,
    launchDate: "01-07-2024",
    networkType: "5G",
    salesPrice: 16499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 21999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO9",
    name: "VIVO Y28S 5G 6/128 ",
    brand: "VIVO",
    series: "Y28S",
    image: VIVOY28SVINTAGERED1,
    launchDate: "01-07-2024",
    networkType: "5G",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 19999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO10",
    name: "VIVO Y28S 5G 4/128 ",
    brand: "VIVO",
    series: "Y28S",
    image: VIVOY28STWINKLINGPURPLE1,
    launchDate: "01-04-2024",
    networkType: "5G",
    salesPrice: 13499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 17999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO11",
    name: "VIVO Y28 5G 6/128 ",
    brand: "VIVO",
    image: Y28CRYSTALPURPLE1,
    launchDate: "07-01-2024",
    networkType: "5G",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 19999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6020 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 15W ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "VIVO12",
    name: "VIVO Y28E 5G 4/64 ",
    brand: "VIVO",
    series: "Y28E",
    image: VIVOY28EBREEZEGREEN1,
    launchDate: "08-07-2024",
    networkType: "5G",
    salesPrice: 10999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4,
    newArrival: false,
    mrp: 14999,
    ram: 4,
    storage: 64,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "13+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },
  {
    id: "VIVO13",
    name: "VIVO Y28E 5G 4/128 ",
    brand: "VIVO",
    series: "Y28E",
    image: VIVOY28EVINTAGERED1,
    launchDate: "08-07-2024",
    networkType: "5G",
    salesPrice: 11999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4,
    newArrival: false,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "13+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "VIVO14",
    name: "VIVO Y18I 4G 4/64 ",
    brand: "VIVO",
    image: VIVOY18IPACEBLACK1,
    launchDate: "23-08-2024",
    networkType: "4G",
    salesPrice: 7999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: false,
    mrp: 11999,
    ram: 4,
    storage: 64,
    specifications: {
      Processor: " Unisoc T612 octa-core ",
      Display: " 6.56-inch (720x1612) 90Hz ",
      "Rear Camera": "13MP + 0.08MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh  ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android 14 ",
    },
  },

  {
    id: "VIVO15",
    name: "VIVO Y300 5G 8/128",
    brand: "VIVO",
    series: "Y300",
    image: VIVOY3005GPHANTOMPURPLE1,
    launchDate: "21-11-2024",
    networkType: "5G",
    salesPrice: 21999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.3,
    newArrival: true,
    mrp: 26999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO16",
    name: "VIVO Y300 5G 8/256",
    brand: "VIVO",
    series: "Y300",
    image: VIVOY3005GPHANTOMPURPLE1,
    launchDate: "21-11-2024",
    networkType: "5G",
    salesPrice: 23999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 28999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256  GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "VIVO17",
    name: "VIVO X200 5G 12/256",
    brand: "VIVO",
    series: "VIVOX200",
    image: VIVOX200NATURALGREEN1,
    launchDate: "12-12-2024",
    networkType: "5G",
    salesPrice: 65999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.8,
    newArrival: true,
    mrp: 74999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 9400 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5800 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
    },
  },

  {
    id: "VIVO18",
    name: "VIVO X200 5G 16/512",
    brand: "VIVO",
    series: "VIVOX200",
    image: VIVOX200NATURALGREEN1,
    launchDate: "12-12-2024",
    networkType: "5G",
    salesPrice: 71999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.8,
    newArrival: true,
    mrp: 80999,
    ram: 16,
    storage: 512,
    specifications: {
      Processor: "MediaTek Dimensity 9400 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "16 GB | 512 GB ",
      Battery: "5800 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
    },
  },

  {
    id: "VIVO19",
    name: "VIVO X200 PRO 5G 16/512",
    brand: "VIVO",
    series: "VIVOX200PRO",
    image: VIVOX200PROTITANIUMGREY1,
    launchDate: "12-12-2024",
    networkType: "5G",
    salesPrice: 94999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.8,
    newArrival: true,
    mrp: 101999,
    ram: 16,
    storage: 512,
    specifications: {
      Processor: "MediaTek Dimensity 9400 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz LTPO AMOLED ",
      "Rear Camera": "50+50+200 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "16 GB | 512 GB ",
      Battery: "6000 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
    },
  },

  //tecno
  {
    id: "TECNO1",
    name: "TECNO CAMON 20",
    image: TECNOCAMON20ARTEDITION1,
    brand: "TECNO",
    launchDate: "09-05-2023",
    networkType: "4G",
    salesPrice: 10499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 13999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Helio G85 Octa core ",
      Display: "6.67″ (16.94 cm) 60Hz AMOLED ",
      "Rear Camera": "64+2+0.08 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v13 ",
    },
  },

  {
    id: "TECNO2",
    name: "TECNO CAMON 20 PRO",
    brand: "TECNO",
    image: TECNOCAMON20PROSERENITYBLUE1,
    launchDate: "09-05-2023",
    networkType: "5G",
    salesPrice: 17999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 24999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 8050 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "64+2+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v13",
    },
  },

  {
    id: "TECNO3",
    name: "TECNO CAMON 30",
    brand: "TECNO",
    image: TECNOCAMON30BASALTICDARK1,
    launchDate: "02-04-2024",
    networkType: "5G",
    salesPrice: 22999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 27999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7020 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz LTPS AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "TECNO4",
    name: "TECNO CAMON 30 PREMIER",
    brand: "TECNO",
    launchDate: "15-11-2024",
    networkType: "5G",
    salesPrice: 39999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.6,
    newArrival: true,
    mrp: 47999,
    image: TECNOCAMON30PREMIERLAVABLACK1,
    ram: 12,
    storage: 512,
    specifications: {
      Processor: "MediaTek Dimensity 8200 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz LTPO AMOLED ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 70W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "TECNO5",
    name: "TECNO PHANTOM X2",
    brand: "TECNO",
    launchDate: "09-01-2023",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.8,
    newArrival: true,
    mrp: 51999,
    ram: 8,
    storage: 256,
    image: TECNOPHANTOMX2MOONLIGHTSILVER1,
    specifications: {
      Processor: "MediaTek Dimensity 9000 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz AMOLED ",
      "Rear Camera": "64+13+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5160 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v12 ",
    },
  },

  {
    id: "TECNO6",
    name: "TECNO PHANTOM X2 PRO",
    brand: "TECNO",
    launchDate: "17-01-2023",
    networkType: "5G",
    salesPrice: 44999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 61999,
    ram: 12,
    image: TECNOPHANTOMX2PROMARSORANGE1,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 9000 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50+13 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5160 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v12 ",
    },
  },

  {
    id: "TECNO7",
    name: "TECNO POVA 6 NEO",
    brand: "TECNO",
    launchDate: "04-04-2024",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15999,
    ram: 6,
    image: TECNOPOVA6NEOAURORACLOUD1,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "108 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "TECNO8",
    name: "TECNO POVA 6 PRO",
    brand: "TECNO",
    launchDate: "26-02-2024",
    networkType: "5G",
    salesPrice: 21999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 24999,
    ram: 12,
    image: TECNOPOVA6PROCOMETGREEN1,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6080 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "108+2+0.08 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "6000 mAh | 70W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "TECNO9",
    name: "TECNO SPARK 30C 5G",
    brand: "TECNO",
    launchDate: "02-10-2024",
    networkType: "5G",
    salesPrice: 10499,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 11999,
    ram: 4,
    storage: 128,
    image: TECNOSPARK30CAURORCLOUD1,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "48 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },
  //MI Phones
  {
    id: "MI1",
    name: "MI 13C 5G 4/128",
    brand: "MI",
    series: "MI13C",
    image: REDMI13CBLACK1,
    launchDate: "06-12-2023",
    networkType: "5G",
    salesPrice: 10499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 13999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },
  {
    id: "MI2",
    name: "MI 13C 5G 6/128",
    brand: "MI",
    series: "MI13C",
    image: REDMI13CGREEN1,
    launchDate: "06-12-2023",
    networkType: "5G",
    salesPrice: 11999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "MI3",
    name: "MI 13C 5G 8/256",
    brand: "MI",
    series: "MI13C",
    image: REDMI13CSILVER1,
    launchDate: "06-12-2023",
    networkType: "5G",
    salesPrice: 13999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "MI4",
    name: "MI NOTE 13 5G 6/128",
    brand: "MI",
    series: "MINOTE135G",
    image: REDMINOTE13WHITE1,
    launchDate: "04-01-2024",
    networkType: "5G",
    salesPrice: 15999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 20999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6080 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "108+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },
  {
    id: "MI5",
    name: "MI NOTE 13 5G 12/256",
    brand: "MI",
    series: "MINOTE135G",
    image: REDMINOTE13PURPLE1,
    launchDate: "04-01-2024",
    networkType: "5G",
    salesPrice: 18999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 24999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6080 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "108+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },
  {
    id: "MI6",
    name: "MI NOTE 13 5G 8/256",
    brand: "MI",
    series: "MINOTE135G",
    image: REDMINOTE13GOLD1,
    launchDate: "04-01-2024",
    networkType: "5G",
    salesPrice: 17999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6080 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "108+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "MI7",
    name: "MI 13 5G 6/128",
    brand: "MI",
    series: "MI135G",
    image: REDMI135GBLACKDIAMOND1,
    launchDate: "03-06-2024",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 17999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.79″ (17.25 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5030 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "MI8",
    name: "MI 13 5G 8/128",
    brand: "MI",
    series: "MI135G",
    image: REDMI135GBLACKDIAMOND1,
    launchDate: "03-06-2024",
    networkType: "5G",
    salesPrice: 14499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 19999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.79″ (17.25 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5030 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "MI9",
    name: "MI Note 13 Pro Plus 5G 8/256",
    brand: "MI",
    series: "MINOTE13PROPLUS",
    image: REDMINOTE13PROPLUS5GFUSIONBLACK1,
    launchDate: "21-09-2023",
    networkType: "5G",
    salesPrice: 27999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 33999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7200 Ultra Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "200+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },
  {
    id: "MI10",
    name: "MI Note 13 Pro Plus 5G 12/256",
    brand: "MI",
    series: "MINOTE13PROPLUS",
    image: REDMINOTE13PROPLUS5GFUSIONBLACK1,
    launchDate: "21-09-2023",
    networkType: "5G",
    salesPrice: 29999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 35999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7200 Ultra Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "200+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },
  {
    id: "MI11",
    name: "MI Note 13 Pro Plus 5G 12/512",
    brand: "MI",
    series: "MINOTE13PROPLUS",
    image: REDMINOTE13PROPLUS5GFUSIONBLACK1,
    launchDate: "21-09-2023",
    networkType: "5G",
    salesPrice: 31999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 37999,
    ram: 12,
    storage: 512,
    specifications: {
      Processor: "MediaTek Dimensity 7200 Ultra Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "200+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },

  //OPPO PHONES
  {
    id: "OPPO2",
    name: "OPPO A17K 4G 3/64 ",
    brand: "OPPO",
    image: OPPOA17kGOLD1,
    launchDate: "13-10-2022",
    networkType: "4G",
    salesPrice: 8499,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.5,
    newArrival: true,
    mrp: 12999,
    ram: 3,
    storage: 64,
    specifications: {
      Processor: "MediaTek Helio G35 Octa core ",
      Display: "6.56″ (16.66 cm) 60Hz IPS LCD ",
      "Rear Camera": "8 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "3 GB | 64 GB ",
      Battery: "5000 mAh ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v12",
    },
  },

  {
    id: "OPPO3",
    name: "OPPO A77 4G 4/128 ",
    brand: "OPPO",
    image: OPPOA77ORANGE1,
    launchDate: "03-08-2022",
    networkType: "4G",
    salesPrice: 15999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 19999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Helio G35 Octa core ",
      Display: "6.56″ (16.66 cm) 60Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v12",
    },
  },

  {
    id: "OPPO5",
    name: "OPPO K12X 5G 8/256 ",
    brand: "OPPO",
    series: "K12X",
    image: OPPOK12XVOILET1,
    launchDate: "29-07-2024",
    networkType: "5G",
    salesPrice: 15999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 18999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "32+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "OPPO6",
    name: "OPPO K12X 5G 6/128 ",
    brand: "OPPO",
    series: "K12X",
    image: OPPOK12XBLUE1,
    launchDate: "29-07-2024",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 16999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "32+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO7",
    name: "OPPO RENO12 PRO 5G 12/256 ",
    brand: "OPPO",
    series: "RENO12PRO",
    image: OPPORENO12PROBROWN1,
    launchDate: "18-06-2024",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 53999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
      Display: "6.7″ (17.02 cm) 120Hz Flexible AMOLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO8",
    name: "OPPO RENO12 PRO 5G 12/512 ",
    brand: "OPPO",
    series: "RENO12PRO",
    image: OPPORENO12PROGOLD1,
    launchDate: "18-06-2024",
    networkType: "5G",
    salesPrice: 40999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 55999,
    ram: 12,
    storage: 512,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
      Display: "6.7″ (17.02 cm) 120Hz Flexible AMOLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "OPPO9",
    name: "OPPO RENO12 5G 8/256 ",
    brand: "OPPO",
    image: OPPORENO12BROWN1,
    launchDate: "18-06-2024",
    networkType: "5G",
    salesPrice: 32999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.4,
    newArrival: true,
    mrp: 43999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
      Display: "6.7″ (17.02 cm) 120Hz Flexible AMOLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO10",
    name: "OPPO F27PRO+ 5G 8/256 ",
    brand: "OPPO",
    series: "F27PRO+",
    image: OPPOF27PROPLUSDUSKPINK1,
    launchDate: "13-06-2024",
    networkType: "5G",
    salesPrice: 29999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 34999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7050 Octa core ",
      Display: "6.7″ (17.02 cm) AMOLED ",
      "Rear Camera": "64 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB| 256 GB ",
      Battery: "5000 mAh Fast Charging ",
      Network: "Single SIM: 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "OPPO11",
    name: "OPPO F27PRO+ 5G 8/128 ",
    brand: "OPPO",
    series: "F27PRO+",
    image: OPPOF27PROPLUSMIDNIGHTNAVY1,
    launchDate: "13-06-2024",
    networkType: "5G",
    salesPrice: 27999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 32999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 7050 Octa core ",
      Display: "6.7″ (17.02 cm) AMOLED ",
      "Rear Camera": "64 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB| 128 GB ",
      Battery: "5000 mAh Fast Charging ",
      Network: "Single SIM: 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO12",
    name: "OPPO F27 5G 8/256 ",
    brand: "OPPO",
    series: "F275G",
    image: OPPOF27AMBERORANGE1,
    launchDate: "20-08-2024",
    salesPrice: 22999,
    specialOffer: true,
    trendingPhone: true,
    rating: 4.2,
    newArrival: true,
    mrp: 28999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core",
      Display: "6.67″ (16.94 cm) 120Hz Flat OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "OPPO13",
    name: "OPPO F27 5G 8/128",
    brand: "OPPO",
    series: "F275G",
    image: OPPOF27EMERALDGREEN1,
    launchDate: "20-08-2024",
    salesPrice: 20999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 26999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core",
      Display: "6.67″ (16.94 cm) 120Hz Flat OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO14",
    name: "OPPO A3 PRO 5G 8/256 ",
    brand: "OPPO",
    series: "A3PRO5G",
    image: OPPOA3PROSTARRYBLACK1,
    launchDate: "21-06-2024",
    networkType: "5G",
    salesPrice: 19999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO15",
    name: "OPPO A3 PRO 5G 8/128 ",
    brand: "OPPO",
    series: "A3PRO5G",
    image: OPPOA3PROMOONLIGHTPURPLE1,
    launchDate: "21-06-2024",
    networkType: "5G",
    salesPrice: 17999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 20999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO16",
    name: "OPPO A3X 5G 4/128 ",
    brand: "OPPO",
    series: "A3X5G",
    image: OPPOA3PROMOONLIGHTPURPLE1,
    launchDate: "02-08-2024",
    networkType: "5G",
    salesPrice: 13499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "8 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO17",
    name: "OPPO A3X 5G 4/64 ",
    brand: "OPPO",
    series: "A3X5G",
    image: OPPOA3XSPARKLEBLACK1,
    launchDate: "02-08-2024",
    networkType: "5G",
    salesPrice: 12499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 14999,
    ram: 4,
    storage: 64,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "8 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "OPPO18",
    name: "OPPO Find X8 5G 12/256 ",
    brand: "OPPO",
    series: "OPPOFINDX8",
    image: OPPOFINDX8SPACEBLACK1,
    launchDate: "21-11-2024",
    salesPrice: 69999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.7,
    newArrival: true,
    mrp: 79999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 9400 Octa core ",
      Display: "6.59″ (16.74 cm) 120Hz ProXDR LTPO ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5630 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
    },
  },

  {
    id: "OPPO19",
    name: "OPPO Find X8 5G 16/512 ",
    brand: "OPPO",
    series: "OPPOFINDX8",
    image: OPPOFINDX8STARGREY1,
    launchDate: "21-11-2024",
    salesPrice: 79999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.7,
    newArrival: true,
    mrp: 89999,
    ram: 16,
    storage: 512,
    specifications: {
      Processor: "MediaTek Dimensity 9400 Octa core ",
      Display: "6.59″ (16.74 cm) 120Hz ProXDR LTPO ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "16 GB | 512 GB ",
      Battery: "5630 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
    },
  },

  {
    id: "OPPO20",
    name: "OPPO Find X8 PRO 5G 16/512 ",
    brand: "OPPO",
    series: "OPPOFINDX8PRO",
    image: OPPOFINDX8PROSPACEBLACK1,
    launchDate: "21-11-2024",
    salesPrice: 99999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.7,
    newArrival: true,
    mrp: 109999,
    ram: 16,
    storage: 512,
    specifications: {
      Processor: "MediaTek Dimensity 9400 Octa core ",
      Display: "6.78″ (17.22 cm) 120Hz ProXDR LTPO ",
      "Rear Camera": "50+50+50+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "16 GB | 512 GB ",
      Battery: "5910 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
    },
  },

  //REALME PHONES
  {
    id: "REALME1",
    name: "REALME C63 4G 4/128",
    brand: "REALME",
    image: REALMEC63,
    launchDate: "31-05-2024",
    salesPrice: 8999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 10999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "Unisoc Octa core ",
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
    },
  },

  {
    id: "REALME2",
    name: "REALME 11 5G 8/256",
    brand: "REALME",
    image: REALME11,
    launchDate: "31-07-2023",
    networkType: "5G",
    salesPrice: 19999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 67W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },

  {
    id: "REALME3",
    name: "REALME 12 5G 8/128",
    brand: "REALME",
    image: REALME12,
    launchDate: "06-03-2024",
    networkType: "5G",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 20999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  //SAMSUNG PHONES
  {
    id: "SAMSUNG1",
    name: "SAMSUNG A16 5G 6/128",
    brand: "SAMSUNG",
    series: "A165G",
    image: SAMSUNGA165GGOLD1,
    launchDate: "07-10-2024",
    salesPrice: 16499,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.7,
    newArrival: true,
    mrp: 19999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.7″ (17.02 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG2",
    name: "SAMSUNG A16 5G 8/128",
    brand: "SAMSUNG",
    series: "A165G",
    image: SASMSUNGA165GBLUEBLACK1,
    launchDate: "07-10-2024",
    salesPrice: 17999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    mrp: 21499,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.7″ (17.02 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG3",
    name: "SAMSUNG A16 5G 8/256",
    brand: "SAMSUNG",
    series: "A165G",
    image: SAMSUNGA165GGOLD1,
    launchDate: "07-10-2024",
    salesPrice: 20999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    mrp: 24499,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.7″ (17.02 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG4",
    name: "SAMSUNG F15 5G 4/128",
    brand: "SAMSUNG",
    series: "F155G",
    image: SAMSUNGF15JAZZYGREEN1,
    launchDate: "04-03-2024",
    networkType: "5G",
    salesPrice: 11999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG5",
    name: "SAMSUNG F15 5G 6/128",
    brand: "SAMSUNG",
    series: "F155G",
    image: SAMSUNGF15ASHBLACK1,
    launchDate: "04-03-2024",
    networkType: "5G",
    salesPrice: 13499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 16999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG6",
    name: "SAMSUNG F15 5G 8/128",
    brand: "SAMSUNG",
    series: "F155G",
    image: SAMSUNGF15GROOVYVIOLET1,
    launchDate: "04-03-2024",
    networkType: "5G",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 17999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG7",
    name: "SAMSUNG A15 5G 8/128",
    brand: "SAMSUNG",
    series: "A155G",
    image: SAMSUNGA15LIGHTBLUE1,
    launchDate: "11-12-2023",
    networkType: "5G",
    salesPrice: 16999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 10999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG8",
    name: "SAMSUNG A15 5G 6/128",
    brand: "SAMSUNG",
    series: "A155G",
    image: SAMSUNGA15BLUEBLACK1,
    launchDate: "11-12-2023",
    networkType: "5G",
    salesPrice: 15499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 19999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG9",
    name: "SAMSUNG A15 5G 8/256",
    brand: "SAMSUNG",
    series: "A155G",
    image: SAMSUNGA15BLUE1,
    launchDate: "11-12-2023",
    networkType: "5G",
    salesPrice: 19999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 24499,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG10",
    name: "SAMSUNG M14 4G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGM14SPPHIREBLUE1,
    launchDate: "07-03-2024",
    networkType: "4G",
    salesPrice: 8999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 17999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "Samsung Exynos 1330 Octa core ",
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG11",
    name: "SAMSUNG A14 5G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGA145GBLACK1,
    launchDate: "04-01-2023",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4,
    newArrival: true,
    mrp: 15499,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "Samsung Exynos 1330 Octa core ",
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG12",
    name: "SAMSUNG A14 4G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGA144GLIGHTGREEN1,
    launchDate: "28-02-2023",
    networkType: "4G",
    salesPrice: 10999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4,
    newArrival: true,
    mrp: 12999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "Samsung Exynos 1330 Octa core ",
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG13",
    name: "SAMSUNG F14 4G 4/64",
    brand: "SAMSUNG",
    image: SAMSUNGF14,
    launchDate: "02-08-2024",
    networkType: "5G",
    salesPrice: 8999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 11999,

    ram: 4,
    storage: 64,
    specifications: {
      Processor: "Samsung Exynos 1330 Octa core ",
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG14",
    name: "SAMSUNG A05S 4G 4/128",
    brand: "SAMSUNG",
    image: A05SBLACK1,
    launchDate: "01-04-2023",
    networkType: "4G",
    salesPrice: 11499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 680 Octa core ",
      Display: "6.7″ (17.02 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v13 ",
    },
  },

  {
    id: "SAMSUNG15",
    name: "SAMSUNG A06 4G 4/64",
    brand: "SAMSUNG",
    image: SAMSUNGA06BLACK1,
    launchDate: "16-08-2024",
    networkType: "4G",
    salesPrice: 9999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.9,
    newArrival: true,
    mrp: 11499,
    ram: 4,
    storage: 64,
    specifcatin: {
      Processor: "MediaTek Helio G85 Octa core ",
      Display: "6.7″ (17.02 cm) 60Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG16",
    name: "SAMSUNG M15 5G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGM15BLUETOPAZ1,
    launchDate: "09-03-2024",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.8,
    newArrival: true,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG17",
    name: "SAMSUNG A35 5G 8/256",
    brand: "SAMSUNG",
    series: "A355G",
    image: SAMSUNGA35AWESOMEICEBLUE1,
    launchDate: "11-03-2024",
    networkType: "5G",
    salesPrice: 28999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 36999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Samsung Exynos 1380 Octa core ",
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+5 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },
  {
    id: "SAMSUNG18",
    name: "SAMSUNG A35 5G 8/128",
    brand: "SAMSUNG",
    series: "A355G",
    image: SAMSUNGA35AWESOMELILAC1,
    launchDate: "11-03-2024",
    networkType: "5G",
    salesPrice: 25999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.2,
    newArrival: true,
    mrp: 36999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Samsung Exynos 1380 Octa core ",
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+5 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "SAMSUNG19",
    name: "SAMSUNG A55 5G 12/256",
    brand: "SAMSUNG",
    series: "A555G",
    image: SAMSUNGA55BLUE1,
    launchDate: "11-03-2024",
    networkType: "5G",
    salesPrice: 39999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 48999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "Samsung Exynos 1480 Octa core ",
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG20",
    name: "SAMSUNG A55 5G 8/128",
    brand: "SAMSUNG",
    series: "A555G",
    image: SAMSUNGA55AWESOMENAVY1,
    launchDate: "11-03-2024",
    networkType: "5G",
    salesPrice: 33999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 42999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Samsung Exynos 1480 Octa core ",
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG21",
    name: "SAMSUNG A55 5G 8/256",
    brand: "SAMSUNG",
    series: "A555G",
    image: SAMSUNGA55AWESOMENAVY1,
    launchDate: "01-04-2024",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 45999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Samsung Exynos 1480 Octa core ",
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "SAMSUNG22",
    name: "SAMSUNG S23 5G 8/256",
    brand: "SAMSUNG",
    series: "S23",
    image: SAMSUNGS23CREAM1,
    launchDate: "17-02-2023",
    networkType: "5G",
    salesPrice: 49999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 95999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 8 Gen 2 Octa core ",
      Display: "6.1″ (15.49 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "3900 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },
  {
    id: "SAMSUNG23",
    name: "SAMSUNG S23 5G 8/128",
    brand: "SAMSUNG",
    series: "S23",
    image: SAMSUNGS23LAVENDER1,
    launchDate: "17-02-2023",
    networkType: "5G",
    salesPrice: 44999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 89999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 8 Gen 2 Octa core ",
      Display: "6.1″ (15.49 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "3900 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
    },
  },
  {
    id: "SAMSUNG24",
    name: "SAMSUNG S24 5G 8/128",
    brand: "SAMSUNG",
    series: "S24",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 62999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.5,
    newArrival: true,
    mrp: 74999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG25",
    name: "SAMSUNG S24 5G 8/256",
    brand: "SAMSUNG",
    series: "S24",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 67999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 79999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG26",
    name: "SAMSUNG S24 5G 8/512",
    brand: "SAMSUNG",
    series: "S24",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 77999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 89999,
    ram: 8,
    storage: 512,
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 512 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG27",
    name: "SAMSUNG s24 plus 12/256",
    brand: "SAMSUNG",
    series: "S24PLUS",
    image: SAMSUNGS24PLUS5GCOBALTVIOLET1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 64999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 99999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "4900 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG28",
    name: "SAMSUNG s24 plus 12/512",
    brand: "SAMSUNG",
    series: "S24PLUS",
    image: SAMSUNGS24PLUS5GCOBALTVIOLET1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 74999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 119999,
    ram: 12,
    storage: 512,
    specifications: {
      Processor: "Samsung Exynos 2400 Deca Core ",
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "4900 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG29",
    name: "SAMSUNG S24 Ultra 5G 12/256",
    brand: "SAMSUNG",
    series: "S24ULTRA",
    image: SAMSUNGS24ULTRA5GTITANIUMGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 109999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 134999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 8 Gen 3 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+12+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG30",
    name: "SAMSUNG S24 Ultra 5G 12/512",
    brand: "SAMSUNG",
    series: "S24ULTRA",
    image: SAMSUNGS24ULTRA5GTITANIUMGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 119999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 144999,
    ram: 12,
    storage: 512,
    specifications: {
      Processor: "Snapdragon 8 Gen 3 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+12+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG31",
    name: "SAMSUNG S24 Ultra 5G 12/ 1TB",
    brand: "SAMSUNG",
    series: "S24ULTRA",
    image: SAMSUNGS24ULTRA5GTITANIUMGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 139999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 144999,
    ram: 12,
    storage: 1000,
    specifications: {
      Processor: "Snapdragon 8 Gen 3 Octa core ",
      Display: "6.8″ (17.27 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+12+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 1TB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG32",
    name: "SAMSUNG S24 FE 5G 8/128",
    brand: "SAMSUNG",
    series: "S24FE",
    image: SAMSUNGS24FE5GGRAPHITE1,
    launchDate: "26-09-2024",
    networkType: "5G",
    salesPrice: 59999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 64999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Samsung Exynos 2400e Deca Core ",
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+8 MP",
      "Front Camera": "10 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "4700 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
  {
    id: "SAMSUNG33",
    name: "SAMSUNG S24 FE 5G 8/256",
    brand: "SAMSUNG",
    series: "S24FE",
    image: SAMSUNGS24FE5GBLUE1,
    launchDate: "26-09-2024",
    networkType: "5G",
    salesPrice: 65999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 79999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Samsung Exynos 2400e Deca Core ",
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+8 MP",
      "Front Camera": "10 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "4700 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  //IQoo

  {
    id: "IQOO1",
    name: "IQOONEO9PRO 8/256",
    brand: "IQOO",
    series: "NEO9PRO",
    image: IQOONEO9PROFIERYRED1,
    launchDate: "22-02-2024",
    networkType: "5G",
    salesPrice: 35899,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 41999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 8 Gen 2 Octa core ",
      Display: "6.78″ (17.22 cm) 144Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5160 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO2",
    name: "IQOO NEO 9 PRO  12/256",
    brand: "IQOO",
    series: "NEO9PRO",
    image: IQOONEO9PROFIERYRED1,
    launchDate: "22-02-2024",
    networkType: "5G",
    salesPrice: 37899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 44999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 8 Gen 2 Octa core ",
      Display: "6.78″ (17.22 cm) 144Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5160 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO3",
    name: "IQOO Z9 8/128",
    brand: "IQOO",
    series: "Z9",
    image: IQOOZ9GRAPHENEBLUE1,
    launchDate: "12-03-2024",
    networkType: "5G",
    salesPrice: 18399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 24999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 7200 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO4",
    name: "IQOO Z9 8/256",
    brand: "IQOO",
    series: "Z9",
    image: IQOOZ9GRAPHENEBLUE1,
    launchDate: "12-03-2024",
    networkType: "5G",
    salesPrice: 20399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 26999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7200 Octa core ",
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO5",
    name: "IQOO Z9 LITE 4/128",
    brand: "IQOO",
    series: "Z9LITE",
    image: IQOOZ9LITEMOCHABROWN1,
    launchDate: "15-07-2024",
    networkType: "5G",
    salesPrice: 10399,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 14499,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "IQOO6",
    name: "IQOO Z9 LITE 6/128",
    brand: "IQOO",
    series: "Z9LITE",
    image: IQOOZ9LITEMOCHABROWN1,
    launchDate: "15-07-2024",
    networkType: "5G",
    salesPrice: 11399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15499,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 6300 Octa core ",
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
    },
  },

  {
    id: "IQOO7",
    name: "IQOO Z9 S 8/128",
    brand: "IQOO",
    series: "Z9S",
    image: IQOOZ9SONYXGREEN1,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 19899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 25999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO8",
    name: "IQOO Z9 S 8/256 ",
    brand: "IQOO",
    series: "Z9S",
    image: IQOOZ9SONYXGREEN1,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 21899,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 27999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO9",
    name: "IQOO Z9 S 12/256 ",
    brand: "IQOO",
    series: "Z9S",
    image: IQOOZ9SONYXGREEN1,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 23899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 29999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO10",
    name: "IQOO Z9 X 4/128",
    brand: "IQOO",
    series: "Z9x",
    image: IQOOZ9XSTORMGREY1,
    launchDate: "16-05-2024",
    networkType: "5G",
    salesPrice: 12399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 17999,
    ram: 4,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 6 Gen 1 Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO11",
    name: "IQOO Z9 X 6/128",
    brand: "IQOO",
    series: "Z9x",
    image: IQOOZ9XSTORMGREY1,
    launchDate: "16-05-2024",
    networkType: "5G",
    salesPrice: 13899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 18999,
    ram: 6,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 6 Gen 1 Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "6000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO12",
    name: "IQOO Z9 X 8/128",
    brand: "IQOO",
    series: "Z9x",
    image: IQOOZ9XSTORMGREY1,
    launchDate: "16-05-2024",
    networkType: "5G",
    salesPrice: 15399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 19999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 6 Gen 1 Octa core ",
      Display: "6.72″ (17.07 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO13",
    name: "IQOO Z9S PRO 8/128",
    brand: "IQOO",
    series: "Z9SPRO",
    image: IQOOZ9SPROLUXEMARBLE3,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 24899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 29999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 7 Gen 3 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO14",
    name: "IQOO Z9S PRO 8/256",
    brand: "IQOO",
    series: "Z9SPRO",
    image: IQOOZ9SPROLUXEMARBLE3,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 26899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 31999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 7 Gen 3 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },

  {
    id: "IQOO15",
    name: "IQOO Z9S PRO 12/256",
    brand: "IQOO",
    series: "Z9SPRO",
    image: IQOOZ9SPROLUXEMARBLE3,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 28899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 33999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 7 Gen 3 Octa core ",
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
    },
  },
];
