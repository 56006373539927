const SocialMediaItems = [
  {
    name: "instagram",
    icon: "Instagram",
    link: "https://www.instagram.com/mobile.house_?igsh=YnFlNjNwbHlwcWl3",
  },
  {
    name: "facebook",
    icon: "Facebook",
    link: "https://www.facebook.com/share/MX2zwui68jR6d9ko/",
  },
  {
    name: "whatsapp",
    icon: "WhatsApp",
    link: "https://api.whatsapp.com/send?phone=9072430483",
  },
  {
    name: "youtube",
    icon: "YouTube",
    link: "https://youtube.com/@mobilehouse5646?si=3A0lZo7Pe6C3j3h1",
  },
];
export default SocialMediaItems;
