import "./App.css";
import PageRoute from "./pageRoute";

function App() {
  return (
    <div>
      <PageRoute />
    </div>
  );
}

export default App;
