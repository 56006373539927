import BANNER1 from "assets/mainBanner/banner1.jpg";
import BANNER2 from "assets/mainBanner/banner2.jpg";
import BANNER3 from "assets/mainBanner/banner3.jpg";
import BANNER4 from "assets/mainBanner/banner4.jpg";
import BANNER5 from "assets/mainBanner/banner5.jpg";
export const MAINBANNER = [
  {
    id: 1,
    image: BANNER1,
    series: ["VIVOX200", "VIVOX200PRO"],
  },
  {
    id: 2,
    image: BANNER2,
    series: ["OPPOFINDX8", "OPPOFINDX8PRO"],
  },
  {
    id: 3,
    image: BANNER3,
    series: ["A165G"],
  },
  {
    id: 4,
    image: BANNER4,
    series: ["Y300"],
  },
  {
    id: 5,
    image: BANNER5,
    series: ["V40", "V40e"],
  },
];
